import React, { useEffect, useState, useCallback } from 'react';
import data from './Onboarding/data';
import stepsComponents from './Onboarding/Onboarding.steps';
import {
  deleteInvitation,
  getKasprInvitations,
  invitePeopleToGetFreeCredit,
  skipSteps,
} from '../../services/api/auth.api';
import { toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router-dom';
import {
  acceptInvitation,
  createOrganization,
  declineInvitation,
  getNewInvitations,
  getOrganization,
  getPublicOrganization,
  joinOrganization,
  updateOrganization,
} from '../../services/api/organization.api';
import { actions } from '../Organization/store/Organization.store';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { capitalizeFirstLetter } from '../../services/utils/tools';
import { useNavigate } from 'react-router-dom';

export function useUserSteps(
  user,
  contentWrapRef,
  profileNextStep,
  setIsLoading,
  excludedSelectors,
  targetSelectors,
) {
  const [steps, setSteps] = useState([]);
  const [selectedStep, setSelectedStep] = useState('personalEmailDiscovered');
  const [invitations, setInvitations] = useState({});
  const [refreshHome, setRefreshHome] = useState(false);
  const { step } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleSkipSteps = async () => {
    if (user.nextStep === -1) return navigate('/leads');
    await skipSteps();
  };

  useEffect(() => {
    getKasprInvitations().then(({ data: invitations }) => {
      const stepsKeys = Object.keys(stepsComponents);
      let filteredSteps = [...user.steps];
      filteredSteps = stepsKeys.map((stp) => {
        let userSteps = filteredSteps.find((filteredStep) => filteredStep.id === stp);

        if (stp === 'inviteFriends') {
          userSteps = {
            ...userSteps,
            id: 'inviteFriends',
            done: false,
            invitations: invitations.invitations,
          };
        }
        stp = {
          ...stepsComponents[stp],
          ...userSteps,
        };
        return stp;
      });

      // Filter out the steps with id "becomeMember" and "leadSaved"
      filteredSteps = filteredSteps.filter(
        (step) => step.id !== 'becomeMember' && step.id !== 'leadSaved',
      );

      for (let index in filteredSteps) {
        if (!filteredSteps[index].done) {
          setSelectedStep(filteredSteps[index] && filteredSteps[index].id);
          break;
        } else {
          setSelectedStep('all_done');
        }
      }

      setSteps(filteredSteps);

      if (step) {
        const stepFromUrl = filteredSteps.find((stp) => stp.urlSegment === step && !stp.done);
        if (stepFromUrl) setSelectedStep(stepFromUrl.id);
      }
    });
  }, [user, invitations]);

  const handleClickOutside = useCallback(
    async (event) => {
      if (
        contentWrapRef.current &&
        !contentWrapRef.current.contains(event.target) &&
        profileNextStep !== -1
      ) {
        // Check if the click is on an excluded element
        const isExcluded = excludedSelectors.some((selector) => {
          return event.target.closest(selector);
        });

        // If the click is not excluded, check if it is a target element
        if (!isExcluded) {
          const isTargetElement = targetSelectors.some((selector) => {
            return event.target.closest(selector);
          });

          // If it is a target element, execute handleSkipSteps
          if (isTargetElement) {
            setIsLoading(true);
            try {
              await handleSkipSteps();
            } catch (error) {
              console.error('Error executing handleSkipSteps:', error);
            } finally {
              setIsLoading(false);
            }
          }
        }
      }
    },
    [
      contentWrapRef,
      profileNextStep,
      excludedSelectors,
      targetSelectors,
      handleSkipSteps,
      setIsLoading,
    ],
  );

  useEffect(() => {
    // Ajouter l'écouteur d'événements
    document.addEventListener('click', handleClickOutside, true);

    // Nettoyer l'écouteur lors du démontage du composant
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [handleClickOutside]);

  const invitationsToast = (data, invitationsToSend) => {
    let styles = {
      success: {
        color: '#fff',
        padding: 10,
        borderRadius: 3,
      },
      error: {
        color: '#f3f3f3',
        padding: 10,
        borderRadius: 3,
      },
    };
    const infos = {
      alreadyInvited: data.declinedInvitations && data.declinedInvitations.alreadyInvited,
      kasprUsers: data.declinedInvitations && data.declinedInvitations.kasprUsers,
    };
    let invitationsSent = invitationsToSend;

    if (
      (infos.alreadyInvited && infos.alreadyInvited.length) ||
      (infos.kasprUsers && infos.kasprUsers.length)
    ) {
      infos.alreadyInvited = data.declinedInvitations.alreadyInvited;
      invitationsSent = invitationsToSend.filter(
        (inv) =>
          !infos.alreadyInvited.some((alr_inv) => alr_inv === inv) &&
          !infos.kasprUsers.some((alr_inv) => alr_inv === inv),
      );
    }

    return toast[invitationsSent.length === 0 ? 'error' : 'success'](
      <div>
        {invitationsSent && invitationsSent.length > 0 && (
          <div style={styles.success}>
            <div>
              <FormattedMessage id={'home.invitationSuccessfullySent'} />{' '}
            </div>
            {invitationsSent.join(', ')}
          </div>
        )}
        {infos.alreadyInvited && infos.alreadyInvited.length > 0 && (
          <div style={styles.error}>
            <FormattedMessage id={'home.invitationAlreadySent'} />
            <div>{infos.alreadyInvited.join(', ')} </div>
          </div>
        )}
        {infos.kasprUsers && infos.kasprUsers.length > 0 && (
          <div style={styles.error}>
            <FormattedMessage id={'home.AlreadyHaveAkasprAccount'} />
            <div>{infos.kasprUsers.join(', ')} </div>
          </div>
        )}
      </div>,
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: 'light',
      },
    );
  };
  const onSendInvitations = async (invitationsToSend, invitationType) => {
    if (invitationsToSend) {
      const { data, statusCode } = await invitePeopleToGetFreeCredit({
        invitees: invitationsToSend,
        isGold: invitationType === 'goldInvitations',
      });

      if (statusCode === 200) {
        invitationsToast(data, invitationsToSend);
        setInvitations(data);
      } else {
        toast.error('An error has occurred while sending invitation: ' + data.messageTranslated);
      }
    }
  };
  const onDeleteInvitations = async (invitee) => {
    await deleteInvitation(invitee);
    const { data: invitations } = await getKasprInvitations();
    setInvitations(invitations);
  };
  const onCreateWorkspace = async ({ isSkip }) => {
    let domainName = user.email.split('@')[1];
    domainName = domainName.split('.');
    domainName.pop();
    domainName = domainName.join('');
    const organization = {
      name: capitalizeFirstLetter(domainName) + ' Workspace',
      domains: [user.email.split('@')[1]],
    };
    const formData = new FormData();

    formData.append('picture', null);
    formData.append('organization', JSON.stringify(organization));
    const { data, statusCode } = await createOrganization(formData);
    if (statusCode === 200) {
      if (!isSkip) {
        toast.success('Workspace  successfully created');
        setTimeout(() => {
          navigate('/leads/');
        }, 2000);
      } else {
        getOrganization().then(({ data: orgData, statusCode }) => {
          if (statusCode === 200) {
            dispatch({ type: actions.GET_ORGANIZATION, payload: orgData });
          }
        });
      }
    } else {
      toast.error(data.messageTranslated || data.message);
    }
  };

  return {
    steps,
    urlSegment: step,
    selectedStep,
    setSelectedStep,
    invitations,
    refreshHome,
    location,
    onSendInvitations,
    onDeleteInvitations,
    onCreateWorkspace,
    handleSkipSteps,
  };
}
export const useBecomeMember = (organization) => {
  const [createWorkspaceToggle, setCreateWorkspaceToggle] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(null);
  let navigate = useNavigate();

  useEffect(() => {
    if (!organization) {
      setLoading(true);
      (async () => {
        const { data: publicOrganization } = await getPublicOrganization();
        setOrganizations(publicOrganization.organizations);
        setLoading(false);
        const { data: newInvitations } = await getNewInvitations();
        setInvitations(newInvitations.invitations);
      })();
    }
    return () => {
      setOrganizations([]);
      setLoading(false);
      setCreateWorkspaceToggle(false);
    };
  }, []);
  const getOwner = (org) => {
    const owner = org.members.find((member) => member.type === 'owner');
    const admin = org.members.find((member) => member.type === 'admin');
    return owner
      ? { firstName: owner.firstName, lastName: owner.lastName }
      : admin
        ? { firstName: admin.firstName, lastName: admin.lastName }
        : { firstName: null, lastName: null };
  };

  const handleJoinOrganization = async (organization) => {
    let { statusCode, data } = await joinOrganization({
      organizationId: organization._id,
      clientDate: new Date(Date.now()),
    });
    if (statusCode == 200) {
      navigate('/leads');
    }
  };
  const handleAcceptInvitation = async (invitation) => {
    setLoading(true);
    const { data, statusCode } = await acceptInvitation({
      organizationId: invitation.organizationId._id,
      inviter: invitation.inviter,
    });
    if (statusCode === 200) {
      navigate('/leads');
    } else if (statusCode >= 400) {
      toast.error(data.message || data.message);
    }
  };

  const handleDeclineInvitation = async (invitation) => {
    const { data, statusCode } = await declineInvitation({
      organizationId: invitation.organizationId._id,
      inviter: invitation.inviter,
    });
    if (statusCode === 200) {
      setInvitations(invitations.filter((el) => el !== invitation));
    }
  };

  return {
    func: {
      handleJoinOrganization,
      handleAcceptInvitation,
      handleDeclineInvitation,
      getOwner,
    },
    states: { createWorkspaceToggle, organizations, loading, invitations },
    setters: {
      setLoading,
      setInvitations,
      setCreateWorkspaceToggle,
      setOrganizations,
    },
  };
};
