import React, { useState, useEffect } from 'react';
import './CustomizeYourPlan.css';
import BillingNavBar from '../../common/BillingNavBar/BillingNavBar';
import { calculateExtraCreditsPrice } from '../../../../services/utils/calculatePrice';
import BottomBanner from '../../../../components/BottomBanner/BottomBanner';
import AddMoreCredits from '../../common/CutomizePlanModal/common/AddMoreCreditsBis/AddMoreCreditsBis';
import Summary from '../../common/CutomizePlanModal/common/SummaryBis/SummaryBis';
import ModifyUsers from '../common/ModifyUsers/ModifyUsers';
import BillingCycle from '../common/BillingCycle/BillingCycle';
import { FormattedMessage } from 'react-intl';

const CustomizeYourPlan = ({
  setToggle,
  selectedPlan,
  subscriptionType,
  selectPlanToPurchase,
  error,
  organizationCredits,
  addCreditsToggle,
  setAddCreditsToggle,
  setSubscriptionType,
  onUserSelect,
  minSeats,
  isVersion2,
  user,
}) => {
  const [planToPurchase, setPlanToPurchase] = useState(selectedPlan);
  const [classToggle, setClassToggle] = useState('');
  const [creditsToAdd, setCreditsToAdd] = useState({
    phoneCredits: { numberOfCredits: 0, index: 0, price: 0, unitPrice: 0 },
    personalEmailCredits: {
      numberOfCredits: 0,
      index: 0,
      price: 0,
      unitPrice: 0,
    },
    exportCredits: { numberOfCredits: 0, index: 0, price: 0, unitPrice: 0 },
  });
  const [nbLicenses, setNbLicenses] = useState(minSeats);

  useEffect(() => {
    window.scrollTo(0, 0);

    let organizationSubscriptionPeriod =
      organizationCredits.plan.numberOfMonths == 12 ? 'yearly' : 'monthly';
    if (organizationSubscriptionPeriod !== subscriptionType || selectedPlan.planId == '0')
      return setCreditsToAdd({
        phoneCredits: { numberOfCredits: 0, index: 0, price: 0, unitPrice: 0 },
        personalEmailCredits: {
          numberOfCredits: 0,
          index: 0,
          price: 0,
          unitPrice: 0,
        },
        exportCredits: { numberOfCredits: 0, index: 0, price: 0, unitPrice: 0 },
      });
    let currentCreditsToAdd = { ...creditsToAdd };
    ['phoneCredits', 'personalEmailCredits', 'exportCredits'].forEach((creditType) => {
      let allExtraCredits = calculateExtraCreditsPrice({
        creditType: creditType,
        isAnnual: subscriptionType == 'yearly',
      }).extraCredits;
      let orgExtraCredits = organizationCredits.plan[creditType];
      let index = 0;

      for (let i in allExtraCredits) {
        if (allExtraCredits[i].amount == orgExtraCredits.extra) index = i;
      }
      currentCreditsToAdd[creditType].numberOfCredits = orgExtraCredits.extra;
      currentCreditsToAdd[creditType].price =
        calculateExtraCreditsPrice({
          creditType: creditType,
          isAnnual: subscriptionType == 'yearly',
          amount: orgExtraCredits.extra,
        }).amount || 0;
      currentCreditsToAdd[creditType].index = parseInt(index);
      currentCreditsToAdd[creditType].unitPrice = 0;
    });
    setCreditsToAdd(currentCreditsToAdd);
  }, [subscriptionType]);

  useEffect(() => {
    if (addCreditsToggle.active) {
      setClassToggle('show-credit-section');
      if (addCreditsToggle.firstIndex) {
        let currentCreditsToAdd = { ...creditsToAdd };
        let allExtraCredits = calculateExtraCreditsPrice({
          creditType: addCreditsToggle.type,
          isAnnual: subscriptionType == 'yearly',
        }).extraCredits;
        currentCreditsToAdd[addCreditsToggle.type].index = 1;
        currentCreditsToAdd[addCreditsToggle.type].price = allExtraCredits[1].price;
        currentCreditsToAdd[addCreditsToggle.type].numberOfCredits = allExtraCredits[1].amount;
        setCreditsToAdd(currentCreditsToAdd);
      }
    } else {
      setClassToggle('show-credit-section');
    }
  }, [addCreditsToggle]);

  useEffect(() => {
    if (selectedPlan) {
      setPlanToPurchase({
        ...selectedPlan,
        yearly: subscriptionType == 'yearly',
      });
    }
  }, [subscriptionType, selectedPlan]);

  const handleAddCredits = (type) => {
    setAddCreditsToggle({ type, active: true });
  };

  const handleSelectPlanWithAddons = () => {
    const creditsState = { ...creditsToAdd };
    const extraCredits = [];
    for (let creditType in creditsState) {
      extraCredits.push({
        amount: creditsState[creditType].numberOfCredits,
        creditType,
      });
    }
    const currentPlan = {
      ...planToPurchase,
      extraCredits: extraCredits.length ? extraCredits : null,
    };
    selectPlanToPurchase(currentPlan);
  };

  const handleSelectUsers = (users) => {
    setPlanToPurchase({ ...planToPurchase, numberOfSeats: users });
    onUserSelect(users, planToPurchase.planId);
  };

  return (
    <>
      <BillingNavBar />
      <div id="customize-plan-page">
        <div className="left-container">
          <h1>
            <FormattedMessage id="billing.customizePlan.upgradeTo" /> {selectedPlan.name}
          </h1>
          <div className="top-container"></div>
          <ModifyUsers
            selectedPlan={selectedPlan}
            onUserSelect={onUserSelect}
            minSeats={minSeats}
            setNbLicenses={setNbLicenses}
          />
          <BillingCycle
            selectedPlan={selectedPlan}
            addOnsPrice={
              creditsToAdd['phoneCredits'].price +
              creditsToAdd['personalEmailCredits'].price +
              creditsToAdd['exportCredits'].price
            }
            onYearlySwitch={setSubscriptionType}
            subscriptionType={subscriptionType}
            organizationCredits={organizationCredits}
            user={user}
            nbLicenses={nbLicenses}
          />
          {isVersion2 && (
            <AddMoreCredits
              addCreditsToggle={addCreditsToggle}
              handleAddCredits={handleAddCredits}
              classToggle={classToggle}
              onAddCredits={setCreditsToAdd}
              creditsToAdd={creditsToAdd}
              selectedPlan={planToPurchase}
              organizationCreditPlan={organizationCredits.plan}
              subscriptionType={subscriptionType}
              plan={planToPurchase}
              user={user}
              organizationCredits={organizationCredits}
            />
          )}
        </div>
        <div className="right-container">
          <div className="summary">
            <Summary
              setAddCreditsToggle={setAddCreditsToggle}
              addCreditsToggle={addCreditsToggle}
              selectedPlan={planToPurchase}
              onYearlySwitch={setSubscriptionType}
              onSelectPlan={handleSelectPlanWithAddons}
              creditsToAdd={creditsToAdd}
              onUserSelect={handleSelectUsers}
              addOnsPrice={
                creditsToAdd['phoneCredits'].price +
                creditsToAdd['personalEmailCredits'].price +
                creditsToAdd['exportCredits'].price
              }
              minSeats={minSeats}
              isVersion2={isVersion2}
              user={user}
              organizationCredits={organizationCredits}
              subscriptionType={subscriptionType}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomizeYourPlan;
