import React from 'react';
import PopUp from '../../../../../components/PopUp/PopUp';
import { FormattedMessage, useIntl } from 'react-intl/lib';
import './ChatWithExpertModal.css';
import { useNavigate } from 'react-router-dom';

const ChatWithExpertModal = ({
  toggle,
  handlePreviousModal,
  handleNextModal,
  handleCloseModal,
}) => {
  let navigate = useNavigate();

  const handleChatWithAnExpert = () => {
    navigate('/leads?openChatbot=true');
  };

  const handleDeclineButton = () => {
    handleNextModal('final-confirmation-modal');
  };

  return (
    <PopUp
      toggle={toggle}
      onClose={handleCloseModal}
      style={{
        width: '80vw',
        maxWidth: '500px',
        height: 'auto',
        position: 'absolute',
        top: '7vh',
      }}
    >
      <div id="chat-with-expert-modal">
        <div className="chat-with-expert-modal-title">
          <img src="/assets/images/chat.svg" alt="chat icon" />
          <h2>
            <FormattedMessage id="cancellationflow.letUsHelp" />
          </h2>
        </div>
        <h1>
          <FormattedMessage id="cancellationflow.letsChatItOut" />
        </h1>
        <p>
          <FormattedMessage
            id="cancellationflow.letsChatItOut.text"
            values={{
              br: <br />,
              strong: (chunk) => <strong> {chunk}</strong>,
            }}
          />
        </p>
        <div id="check-it-out-btn">
          <button
            className="btn btn1 btn-ripple"
            style={{ width: '100%' }}
            onClick={handleChatWithAnExpert}
          >
            <FormattedMessage id="cancellationflow.letsChatItOut.chatWithAnExpert" />
          </button>
        </div>
        <div className="buttons-container">
          <button className=" btn2 ripple-btn" onClick={handlePreviousModal}>
            <FormattedMessage id={'cancellationflow.Back'} />
          </button>
          <button
            className={'btn btn1 ripple-btn btn-outline-primary'}
            onClick={handleDeclineButton}
          >
            <FormattedMessage id="decline" />
          </button>
        </div>
      </div>
    </PopUp>
  );
};

export default ChatWithExpertModal;
