import React, { useState } from 'react';
import './BillingCycleClient.css';
import { FormattedMessage } from 'react-intl';
import FixPriceInfo from '../../../common/CutomizePlanModal/common/FixPriceInfo/FixPriceInfo';
import CustomToolTip from '../../../../../components/CustomToolTip/CustomToolTip';

const BillingCycle = ({
  selectedPlan,
  addOnsPrice,
  onYearlySwitch,
  subscriptionType,
  organizationCredits,
  user,
  nbLicenses,
  restrictions,
}) => {
  const [isActive, setIsActive] = useState(
    selectedPlan.planId === 'plan_3'
      ? 'annual'
      : subscriptionType === 'yearly'
        ? 'annual'
        : 'monthly',
  );
  const monthlyIsDisabled = restrictions.downgrade_months.value;

  const handleAnnualClick = () => {
    setIsActive('annual');
    onYearlySwitch('yearly');
  };

  const handleMonthlyClick = () => {
    setIsActive('monthly');
    onYearlySwitch('monthly');
  };

  return (
    <div id="billing-cycle-client">
      <h1>
        <FormattedMessage id="billing.customizePlan.chooseBillingCycle" />
      </h1>
      <div className="billing-cycle-ctn">
        <div
          className={`billing-option annually-ctn-wrapper ${isActive === 'annual' ? 'active' : ''}`}
          onClick={handleAnnualClick}
        >
          <div className="annually-ctn">
            <div className="left">
              <p>
                <FormattedMessage id="billing.annual" />
              </p>
              <div className="price">
                <h2>
                  <FixPriceInfo
                    selectedPlan={selectedPlan}
                    addOnsPrice={addOnsPrice}
                    onYearlySwitch={onYearlySwitch}
                    organizationCredits={organizationCredits}
                    user={user}
                    planType={'yearly'}
                    nbLicenses={nbLicenses}
                  />
                </h2>
                <span style={{ textTransform: 'lowercase' }}>
                  /<FormattedMessage id="billing.customizePlan.mo" />
                </span>
              </div>
            </div>
            {(selectedPlan.planId === 'plan_1' || selectedPlan.planId === 'plan_2') && (
              <div className="save-percent">
                <p>
                  <FormattedMessage
                    id="billing.plan.saveXPercent"
                    values={{
                      value:
                        selectedPlan.planId === 'plan_1'
                          ? '25'
                          : selectedPlan.planId === 'plan_2'
                            ? '20'
                            : '0',
                    }}
                  />
                </p>
              </div>
            )}
          </div>
        </div>
        {selectedPlan.planId !== 'plan_3' && (
          <div>
            {/* Restriction : downgrade_months */}
            {monthlyIsDisabled ? (
              <>
                <CustomToolTip
                  style={{
                    maxWidth: '20vw',
                    pointerEvents: 'none',
                  }}
                  element={<FormattedMessage id={restrictions.downgrade_months.error_message} />}
                >
                  <div className={'billing-option monthly-ctn-wrapper'}>
                    <div className="monthly-ctn disabled">
                      <div className="left">
                        <p>
                          <FormattedMessage id="billing.billMonthly" />
                        </p>
                        <div className="price">
                          <h2>
                            <FixPriceInfo
                              selectedPlan={selectedPlan}
                              addOnsPrice={addOnsPrice}
                              onYearlySwitch={onYearlySwitch}
                              organizationCredits={organizationCredits}
                              user={user}
                              planType={'monthly'}
                              nbLicenses={nbLicenses}
                            />
                          </h2>
                          <span style={{ textTransform: 'lowercase' }}>
                            /<FormattedMessage id="billing.customizePlan.mo" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CustomToolTip>
              </>
            ) : (
              <div
                className={`billing-option monthly-ctn-wrapper ${
                  isActive === 'annual' ? '' : 'active-monthly'
                }`}
                onClick={handleMonthlyClick}
              >
                <div className="monthly-ctn">
                  <div className="left">
                    <p>
                      <FormattedMessage id="billing.billMonthly" />
                    </p>
                    <div className="price">
                      <h2>
                        <FixPriceInfo
                          selectedPlan={selectedPlan}
                          addOnsPrice={addOnsPrice}
                          onYearlySwitch={onYearlySwitch}
                          organizationCredits={organizationCredits}
                          user={user}
                          planType={'monthly'}
                          nbLicenses={nbLicenses}
                        />
                      </h2>
                      <span style={{ textTransform: 'lowercase' }}>
                        /<FormattedMessage id="billing.customizePlan.mo" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingCycle;
